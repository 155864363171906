import React from 'react';
import './public.css';
import AudioPlayer from "./components/AudioPlayer";

function App() {
  return (
      <div className="">
          <header>

          </header>
          <article>
            <AudioPlayer />
          </article>
          <footer>

          </footer>
      </div>
  );
}

export default App;
